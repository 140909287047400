import styled from 'styled-components'
import Image from 'next/image'
import { Grid, Typography } from '@material-ui/core'
import { Button } from '../button/button'

export const StyledImage = styled(Image)`
  position: relative;
  display: inline-block;
  width: 100%;
`

export const StyledTitle = styled(Typography)`
  font-weight: 500;
  text-align: center;
  font-size: ${props => props.theme.typography.pxToRem(23)};
  padding-top: ${props => props.theme.typography.pxToRem(34)};
  margin: 0;

  ${props=> props.theme.breakpoints.up('md')} {
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
    text-align: left;
    font-size: ${props => props.theme.typography.pxToRem(32)};
    padding-top: 0;
    max-width: ${props => props.theme.typography.pxToRem(456)};
    line-height: ${props => props.theme.typography.pxToRem(45)};
  }
`

export const StyledDescription = styled(Typography)`
  display: none;

  ${props=> props.theme.breakpoints.up('md')} {
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
    font-size: ${props => props.theme.typography.pxToRem(17)};
    padding-top: ${props => props.theme.typography.pxToRem(41)};
    text-align: left;
    max-width: ${props => props.theme.typography.pxToRem(426)};
    line-height: ${props => props.theme.typography.pxToRem(35)};
  }
`

export const StyleCta = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(36)};
  padding: ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(30)};
  font-size: ${props => props.theme.typography.pxToRem(16)};

  ${props=> props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(46)};
    padding: ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(30)};
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`

export const StyledCarouselImageWrapper = styled(Grid)`
  width: 100%;
  height: 0;
  padding-bottom: ${209/307*100}%;
  margin-top: 0 !important;

  ${props=> props.theme.breakpoints.up('md')} {
    padding-bottom: 0;
    width: ${props => props.theme.typography.pxToRem(798)};
    height: ${props => props.theme.typography.pxToRem(542)};
    margin-left: ${props => props.theme.typography.pxToRem(10)};
    margin-right: ${props => props.theme.typography.pxToRem(10)};
  }
`

export const StyledContentSection = styled(Grid)`
  width: 100%;
  position: relative;
  margin: auto;
`

export const StyledOfferPromotionCarouselSection = styled(Grid)`
  width: 100%;
  position: relative;
`

export const StyledOfferPromotionCarouselContainer = styled.div<{$variant?: 'offer' | 'promotion'}>`
  width: 100%;

  ${props=> props.theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
    flex-direction: ${props => props.$variant === 'offer' ? 'row' : 'row-reverse'};
    gap: ${props => props.theme.typography.pxToRem(94)};
  }
`

export const StyledOfferPromotionCarouselWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${props=> props.theme.breakpoints.up('md')} {
    gap: ${props => props.theme.typography.pxToRem(170)};
  }
`
